/* Generated file. Please read https://github.com/jobteaser/next-jobteaser/blob/master/README.md#i18n. */
import { literal, union } from "valibot"

export const i18nLocaleSchema = union([
  literal("ca"),
  literal("cs"),
  literal("da"),
  literal("de"),
  literal("en"),
  literal("es"),
  literal("fi"),
  literal("fr"),
  literal("gl"),
  literal("it"),
  literal("nl"),
  literal("no"),
  literal("pl"),
  literal("pt"),
  literal("sv"),
])
