import type { Asset } from "./types"

export function getAssetImportUrl(asset: Asset): string {
  if (typeof asset === "string") {
    return asset
  }

  if (`src` in asset) {
    return asset.src
  }

  if (`default` in asset) {
    return asset.default.src
  }

  return ""
}
