import type { CurrentUser } from "@/modules/user/api/types"

import { useQuery } from "@tanstack/react-query"

import { useLocale } from "@/modules/locales/useLocale"
import { CURRENT_USER_QUERY_KEY } from "@/modules/user/api/constants"
import { getCurrentUser } from "@/modules/user/api/getCurrentUser"
import { getCurrentUserQueryKeys } from "@/modules/user/api/getUserQueryKeys"
import { QUERY_CACHE_TIME, QUERY_STALE_TIME } from "@/modules/user/client/constants"

export type UseCurrentUserFetch = {
  dataCurrentUser?: CurrentUser
  isLoadingCurrentUser: boolean
  isSuccessCurrentUser: boolean
  isErrorCurrentUser: boolean
}
export const useCurrentUserFetch = (): UseCurrentUserFetch => {
  const currentLocale = useLocale()
  const {
    data: dataCurrentUser,
    isPending: isLoadingCurrentUser,
    isSuccess: isSuccessCurrentUser,
    isError: isErrorCurrentUser,
  } = useQuery({
    gcTime: QUERY_CACHE_TIME,
    queryFn: () =>
      getCurrentUser({
        hostname: typeof window !== "undefined" ? window.location.hostname : "",
        locale: currentLocale,
      }),
    // eslint-disable-next-line @tanstack/query/exhaustive-deps
    queryKey: getCurrentUserQueryKeys({ queryKey: CURRENT_USER_QUERY_KEY }),
    staleTime: QUERY_STALE_TIME,
  })

  return {
    dataCurrentUser,
    isErrorCurrentUser,
    isLoadingCurrentUser,
    isSuccessCurrentUser,
  }
}
