import type {
  CURRENT_USER_QUERY_KEY,
  CURRENT_USER_SEARCH_CONFIG_QUERY_KEY,
  CURRENT_USER_SEARCH_CRITERIA_QUERY_KEY,
} from "./constants"

export function getCurrentUserQueryKeys({ queryKey }: { queryKey: typeof CURRENT_USER_QUERY_KEY }): (unknown | null)[] {
  return [queryKey]
}

export function getCurrentUserSearchConfigQueryKeys({
  queryKey,
  isLogged,
}: {
  queryKey: typeof CURRENT_USER_SEARCH_CONFIG_QUERY_KEY
  isLogged: boolean
}): (unknown | null)[] {
  return [queryKey, isLogged]
}

export function getCurrentUserSearchCriteriaKeys({
  queryKey,
  isLogged,
}: {
  queryKey: typeof CURRENT_USER_SEARCH_CRITERIA_QUERY_KEY
  isLogged: boolean
}): (unknown | null)[] {
  return [queryKey, isLogged]
}
