import type { BaseIssue, BaseSchema } from "valibot"

import { safeParse } from "valibot"

import { logger } from "@/modules/monitoring/logger"

export const handleSchemaValidation = <T>(
  data: T,
  schema: BaseSchema<unknown, T, BaseIssue<unknown>>,
  endpoint: string
): T => {
  const parsedData = safeParse(schema, data)
  if (parsedData.success) {
    return parsedData.output
  }
  logger.error(`ValidationError for endpoint ${endpoint}: ${JSON.stringify(parsedData.issues)}`)
  return data
}
