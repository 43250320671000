import {
  array,
  boolean,
  null as valibotNull,
  nullable,
  number,
  object,
  optional,
  picklist,
  string,
  union,
} from "valibot"

import { i18nLocaleSchema } from "@/modules/i18n/schemas"
import {
  COMPANY_ADMIN_ROLES,
  DEFAULT_USER_ROLE,
  JT_ADMIN_ROLES,
  ONBOARDING_STATUSES,
  RECRUITER_ADMIN_ROLES,
  SCHOOL_ADMIN_ROLES,
  STUDENT_ROLES,
} from "@/modules/user/api/types"

export const studentRoleSchema = picklist(STUDENT_ROLES)
const defaultUserRoleSchema = picklist(DEFAULT_USER_ROLE)
const jtAdminRoleSchema = picklist(JT_ADMIN_ROLES)
const schoolAdminRoleSchema = picklist(SCHOOL_ADMIN_ROLES)
const companyAdminRoleSchema = picklist(COMPANY_ADMIN_ROLES)
const recruiterAdminRoleSchema = picklist(RECRUITER_ADMIN_ROLES)

export const currentUserRoleSchema = union([
  defaultUserRoleSchema,
  studentRoleSchema,
  jtAdminRoleSchema,
  schoolAdminRoleSchema,
  companyAdminRoleSchema,
  recruiterAdminRoleSchema,
])

export const currentUserCurriculumIdsSchema = union([array(string()), valibotNull()])

const onboardingStatusesSchema = picklist(ONBOARDING_STATUSES)

const currentUserAttributesSchema = object({
  anonymous: boolean(),
  anyCompanyRole: boolean(),
  avatarUrl: nullable(string()),
  careerCenterId: nullable(string()),
  careerCenterShortName: nullable(string()),
  careerCenterUuid: nullable(string()),
  companyPermalink: nullable(string()),
  companyUuid: nullable(string()),
  country: nullable(string()),
  curriculumIds: currentUserCurriculumIdsSchema,
  dailyJobOffersAlert: nullable(boolean()),
  email: nullable(string()),
  familyName: nullable(string()),
  firstName: nullable(string()),
  givenName: nullable(string()),
  hasAlumniCurriculum: boolean(),
  hasCommunityDiscussions: nullable(boolean()),
  hasCommunityInsights: nullable(boolean()),
  hasCommunityModule: boolean(),
  hasExclusiveOffers: boolean(),
  hasSchoolProfile: nullable(boolean()),
  isAdmin: nullable(boolean()),
  isAlumni: nullable(boolean()),
  isCcSuperAdmin: nullable(boolean()),
  isHiddenAdmin: nullable(boolean()),
  isStudent: nullable(boolean()),
  isWithCompanySpace: nullable(boolean()),
  jwtToken: string(),
  lastName: nullable(string()),
  lastSignInAt: nullable(string()),
  locales: optional(array(i18nLocaleSchema)),
  onboardingStatus: onboardingStatusesSchema,
  recruiter: nullable(boolean()),
  roleTitle: currentUserRoleSchema,
  schoolId: nullable(number()),
  showJobRoles: nullable(boolean()),
  timeZone: string(),
  uuid: nullable(string()),
})

const currentUserRelationShipsSchema = object({
  careerCenter: object({
    data: object({
      id: string(),
      type: string(),
    }),
  }),
})

export const currentUserSchema = object({
  attributes: currentUserAttributesSchema,
  id: string(),
  isLogged: boolean(),
  relationships: currentUserRelationShipsSchema,
  type: string(),
})

export const currentUserResponseSchema = object({
  data: currentUserSchema,
})
