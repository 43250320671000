import type { PagesRouterPageProps } from "@/types"
import type { AppProps } from "next/app"
import type { FunctionComponent } from "react"

import { hydrate, QueryClientProvider } from "@tanstack/react-query"
import dynamic from "next/dynamic"
import { Poppins } from "next/font/google"
import localFont from "next/font/local"
import Head from "next/head"
import { useState } from "react"

import { DEBUG_MENU } from "@/modules/debug/constants/flags"
import { TranslationProvider } from "@/modules/i18n/components/TranslationProvider"
import { isUiShiftCompatibleWithPathname } from "@/modules/layouts/helpers/isUiShiftCompatibleWithPathname"
import { DatadogProvider } from "@/modules/monitoring/Datadog/DatadogProvider"
import { TrackJsProvider } from "@/modules/monitoring/TrackJs/TrackJsProvider"
import { NotificationProvider } from "@/modules/notification/Providers/NotificationProvider"
import logo57 from "@/modules/pwa/assets/logo_57.png"
import logo76 from "@/modules/pwa/assets/logo_76.png"
import logo120 from "@/modules/pwa/assets/logo_120.png"
import logo152 from "@/modules/pwa/assets/logo_152.png"
import logo167 from "@/modules/pwa/assets/logo_167.png"
import logo180 from "@/modules/pwa/assets/logo_180.png"
import { createComponentQueryClient } from "@/modules/queryClient/createComponentQueryClient"
import { ServerContext } from "@/modules/server/context"
import { getAssetsUrl } from "@/modules/staticAssets/assetsManager/assetsManager"
import { getFaviconPath } from "@/modules/staticAssets/assetsManager/faviconManager"
import { getAssetImportUrl } from "@/modules/staticAssets/getAssetImportUrl"
import { TrackingProviderWrapper } from "@/modules/tracking/components/TrackingProviderWrapper"
import { isFlagEnabled } from "@/modules/unleash/isFlagEnabled"

import "@/modules/layouts/styles/GlobalStyles.css"
import "@/modules/layouts/styles/GlobalZindexes.css"
import "@jobteaser/spark/styles/main.css"
import "@jobteaser/ui-kit/styles/core.css"

const DynamicReactQueryDevtools = dynamic(
  () => import("@tanstack/react-query-devtools").then(({ ReactQueryDevtools }) => ReactQueryDevtools),
  {
    ssr: false,
  }
)

const DynamicDebugMenu = dynamic(() => import("@/modules/debug/components/DebugMenu"))

const poppins = Poppins({ display: "swap", subsets: ["latin"], weight: ["400", "500", "600", "700"] })
const polysans = localFont({ display: "swap", src: "../modules/layouts/fonts/polysans700.woff2" })

const App: FunctionComponent<AppProps<PagesRouterPageProps>> = ({ Component, pageProps, router }) => {
  const [queryClient] = useState(() => createComponentQueryClient())
  hydrate(queryClient, pageProps.dehydratedState)

  const unleashContext = pageProps.serverContext?.unleashContext || {}
  const activeUnleashFlags = pageProps.serverContext?.activeFlags || []

  // Until all the pages are compatible with UI Shift, we only enable some of them.
  const [pathname] = router.asPath.split("?")
  const isUIShiftCompatible = isUiShiftCompatibleWithPathname(pathname)
  const requestId = pageProps.serverContext?.requestId

  const poppinsFont = `${poppins.style.fontFamily}`
  const polysansFont = `${polysans.style.fontFamily}`
  const fontFamily = `${poppinsFont}, Helvetica, arial, "sans-serif"`
  const fontHeading = `${polysansFont}, Helvetica, arial, "sans-serif"`

  const isDebugMenuEnabled = isFlagEnabled(activeUnleashFlags, DEBUG_MENU)

  return (
    <>
      <Head>
        <title>JobTeaser</title>
        <link rel="icon" href={`${getAssetsUrl()}${getFaviconPath()}`} />
        <style>@layer modules, ui, base;</style>
        {/* The data-font meta is kind of a hack to send the font-family value to _document */}
        <meta data-font={fontFamily} />
        <link rel="apple-touch-icon" href={getAssetImportUrl(logo57)} />
        <link rel="apple-touch-icon" sizes="76x76" href={getAssetImportUrl(logo76)} />
        <link rel="apple-touch-icon" sizes="120x120" href={getAssetImportUrl(logo120)} />
        <link rel="apple-touch-icon" sizes="152x152" href={getAssetImportUrl(logo152)} />
        <link rel="apple-touch-icon" sizes="167x167" href={getAssetImportUrl(logo167)} />
        <link rel="apple-touch-icon" sizes="180x180" href={getAssetImportUrl(logo180)} />
      </Head>
      <style
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{
          __html: `
        :root {
          --fontFamily: ${fontFamily};
          --fontHeading: ${fontHeading};
          --sk-font-families-polysans: ${fontHeading};
          --sk-font-families-poppins: ${fontFamily};
        }
      `,
        }}
      />
      <ServerContext.Provider value={pageProps.serverContext}>
        <TranslationProvider pageProps={pageProps}>
          <NotificationProvider>
            <DatadogProvider>
              <TrackJsProvider
                activeUnleashFlags={pageProps.serverContext?.activeFlags || []}
                trackJsMetadata={pageProps?.trackJsMetadata}
                enabledTrackJs={pageProps.enableTrackJs}
              >
                <QueryClientProvider client={queryClient}>
                  <TrackingProviderWrapper pageViewProps={pageProps?.pageViewProps} unleashContext={unleashContext}>
                    {/* Temporary div used only for UI Shift transition. Using this, instead of body, so we could update it in app file*/}
                    <div {...(isUIShiftCompatible && { "data-theme": "jobteaser-uishift", id: "jobteaser-uishift" })}>
                      {isDebugMenuEnabled && (
                        <DynamicDebugMenu
                          requestId={requestId || ""}
                          grafanaDebugUrl={pageProps.serverContext?.grafanaDebugUrl || ""}
                        />
                      )}
                      <Component {...pageProps} />
                    </div>
                  </TrackingProviderWrapper>
                  {process.env.NODE_ENV === "development" && <DynamicReactQueryDevtools />}
                </QueryClientProvider>
              </TrackJsProvider>
            </DatadogProvider>
          </NotificationProvider>
        </TranslationProvider>
      </ServerContext.Provider>
    </>
  )
}

export default App
