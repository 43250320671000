import { isPureObject } from "@/modules/format/object/isPureObject"
import { toCamelCase } from "@/modules/format/string/toCamelCase"

export const toCamelCaseKeys = <T>(item: unknown): T => {
  if (Array.isArray(item)) {
    return item.map((element: unknown) => toCamelCaseKeys(element)) as T
  }

  if (isPureObject(item)) {
    return Object.fromEntries(
      Object.entries(item as Record<string, unknown>).map(([key, value]: [string, unknown]) => [
        toCamelCase(key),
        isPureObject(value) || Array.isArray(value) ? toCamelCaseKeys(value) : value,
      ])
    ) as T
  }

  return item as T
}
