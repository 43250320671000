import type { TrackingPageViewProps } from "@/modules/tracking/types"
import type { UnleashContext } from "@/modules/unleash/types"
import type { FunctionComponent, PropsWithChildren } from "react"

import { useContext } from "react"

import { ServerContext } from "@/modules/server/context"
import { useCurrentUserFetch } from "@/modules/user/client/hooks/useCurrentUserFetch"

import { TrackingProvider } from "./TrackingProvider"

type TrackingProviderWrapperProps = PropsWithChildren<{
  pageViewProps: TrackingPageViewProps
  unleashContext: UnleashContext
}>

export const TrackingProviderWrapper: FunctionComponent<TrackingProviderWrapperProps> = ({
  children,
  pageViewProps,
  unleashContext,
}) => {
  const { activeFlags } = useContext(ServerContext) || { activeFlags: [] }
  const { dataCurrentUser: currentUser, isLoadingCurrentUser, isSuccessCurrentUser } = useCurrentUserFetch()

  return (
    <TrackingProvider
      activeFlags={activeFlags}
      currentUser={!isLoadingCurrentUser && isSuccessCurrentUser && currentUser ? currentUser : undefined}
      pageViewProps={pageViewProps}
      unleashContext={unleashContext}
    >
      {children}
    </TrackingProvider>
  )
}
